<template>
  <div class="container">
    <div class="banner">
      <img :src="banner?.src" :title="banner?.title" />
    </div>
    <div class="introduce">
      <div class="navtitle">
        <div class="title">运东简介</div>
        <div class="sub_title">INTRODUCTION TO YUNDONG</div>
        <div class="horizontal_line"></div>
      </div>
      <div class="introduce_main">
        <div class="main_l">
          <span class="main_l_content">
            <p>{{ intro }}</p>
          </span>
          <div class="main_l_b">
            <span>{{ slogan }}</span>
          </div>
        </div>
        <img v-lazy="image?.src" :title="image?.title" />
      </div>
    </div>
    <div class="values">
      <div class="values_l">
        <span class="title">品牌使命</span>
        <span class="tip">{{ mission }}</span>
      </div>
      <div class="values_r">
        <span class="title">价值观</span>
        <span class="tip">{{ values }}</span>
      </div>
    </div>
    <div class="brand">
      <div class="navtitle">
        <div class="title">品牌价值</div>
        <div class="sub_title">BRAND VALUE</div>
        <div class="horizontal_line"></div>
      </div>
      <div class="brand_list">
        <div class="brand_list_item" v-for="(item, index) in adv" :key="index">
          <img :src="item.icon" />
          <span>{{ item.title }}</span>
          <div class="line"></div>
          <p>{{ item.intro }}</p>
        </div>
      </div>
    </div>
    <div class="history">
      <div class="main">
        <div class="navtitle">
          <div class="title">发展历程</div>
          <div class="sub_title">DEVELOPMENT COURSE</div>
          <div class="horizontal_line" style="background: #fff"></div>
        </div>
        <div class="steps">
          <div class="left_btn" @click="changHistory('left')">←</div>
          <div class="steps_list">
            <div
              class="steps_list_item"
              ref="stepsItem"
              :style="{ transform: `translateX(${translateX}px)` }"
              v-for="(item, index) in histories"
              :key="index"
            >
              <div class="year">
                {{ item.start_year }} ~ {{ item.end_year }}
              </div>
              <div class="line"></div>
              <div class="content">{{ item.intro }}</div>
            </div>
          </div>
          <div class="right_btn" @click="changHistory('right')">→</div>
        </div>
      </div>
    </div>
    <div class="honor">
      <div class="navtitle">
        <div class="title">企业荣誉</div>
        <div class="sub_title">CORPORATE HONORS</div>
        <div class="horizontal_line"></div>
      </div>
      <div class="honor_list">
        <div
          class="honor_list_item"
          v-for="(item, index) in honors"
          :key="index"
        >
          <div class="year">{{ item.got_year }}</div>
          <div class="line"></div>
          <div class="content">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="main">
        <div class="title">寻找发光的你，挑战高薪，“职”等你来</div>
        <div class="sub_title">Join us</div>
        <span>
          长期招聘：
          <span v-for="(item, index) in jobs" :key="index">
            {{ item }}<span v-if="index != jobs.length - 1">、</span>
          </span>
        </span>
        <span class="email">简历投递邮箱：{{ resume_email }}</span>
      </div>
    </div>
    <loading v-if="isLoading" />
  </div>
</template>
<script>
import { getData } from "@/api/aboutUs";
export default {
  name: "aboutUs",
  data() {
    return {
      current: 0,
      translateX: 0,
      banner: {},
      histories: [],
      intro: "",
      mission: "",
      slogan: "",
      honors: [],
      adv: [],
      image: {},
      resume_email: "",
      values: "",
      jobs: [],
      isLoading: false,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      try {
        this.isLoading = true;
        const { data: res } = await getData();
        this.banner = res.banner;
        res.histories.sort((a, b) => {
          return Number(a.start_year) - Number(b.start_year);
        });
        this.histories = res.histories;
        this.intro = res.intro;
        this.mission = res.mission;
        this.slogan = res.slogan;
        res.honors.sort((a, b) => {
          return Number(a.got_year) - Number(b.got_year);
        });
        this.honors = res.honors;
        this.adv = res.adv;
        this.image = res.image;
        this.resume_email = res.resume_email;
        this.values = res.values;
        this.jobs = res.jobs;
      } finally {
        this.isLoading = false;
      }
    },
    changHistory(type) {
      let width = this.$refs.stepsItem[0].offsetWidth || 0;
      if (type == "left") {
        if (!this.current) return;
        this.translateX = this.translateX + width;
        this.current = this.current - 1;
      } else if (type == "right") {
        if (this.histories.length - this.current <= 4) return;
        this.translateX = this.translateX - width;
        this.current = this.current + 1;
      }
    },
  },
};
</script>
<style scoped lang="scss">
$width: 1600px;
.container {
  box-sizing: border-box;
  width: 100vw;
  height: auto;
  .banner {
    width: 100%;
    height: 530px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .introduce {
    width: $width;
    margin: 0 auto;
    background: #fff;
    padding: 80px 50px;
    box-sizing: border-box;
    &_main {
      display: flex;
      margin: 50px 0 0 0;
      justify-content: flex-end;
      position: relative;
      .main_l {
        padding: 50px 30px;
        box-sizing: border-box;
        background: #ffa71d;
        width: 750px;
        height: 500px;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-weight: 14px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0px;
        &_content {
          display: inline-block;
          text-indent: 2rem;
          line-height: 25px;
          p {
            font-size: 14px;
          }
        }
        &_b {
          font-size: 24px;
          font-weight: bold;
          display: flex;
          flex-direction: column;
          span {
            margin-top: 10px;
          }
        }
      }
      img {
        width: 900px;
        height: 700px;
        object-fit: cover;
      }
    }
  }
  .values {
    width: 100%;
    height: 320px;
    display: flex;
    &_l {
      background: url("../../assets/image/ppsm.png") 100% 100% no-repeat;
      .title {
        font-size: 24px;
        font-weight: bold;
        margin-left: 200px;
      }
      .tip {
        font-size: 16px;
        margin-left: 200px;
      }
    }
    &_r {
      background: url("../../assets/image/jzg.png") 100% 100% no-repeat;
      .title {
        font-size: 24px;
        font-weight: bold;
        margin-left: 60px;
      }
      .tip {
        font-size: 16px;
        margin-left: 60px;
      }
    }
    &_l,
    &_r {
      flex: 1;
      box-sizing: border-box;
      background-position: center;
      background-size: cover;
      color: #fff;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      padding-top: 58px;
      box-sizing: border-box;
    }
  }
  .brand {
    width: $width;
    margin: 0 auto;
    background: #fff;
    padding: 80px 50px;
    box-sizing: border-box;
    &_list {
      display: flex;
      column-gap: 22px;
      margin-top: 60px;
      &_item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid #eeeeee;
        padding: 66px 54px;
        box-sizing: border-box;
        row-gap: 25px;
        transition: all 0.3s ease;
        &:hover {
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
        }
        img {
          width: 66px;
          height: 66px;
          border-radius: 50%;
        }
        span {
          color: #333;
          font-size: 22px;
          font-weight: bold;
        }
        .line {
          width: 30px;
          height: 4px;
          background: #ffa71d;
        }
        p {
          font-size: 16px;
          color: #666;
          text-align: center;
          line-height: 28px;
        }
      }
    }
  }
  .history {
    width: 100vw;
    height: 600px;
    background: #fff9ef;
    .main {
      width: $width;
      height: 100%;
      padding: 80px 50px;
      box-sizing: border-box;
      margin: 0 auto;
      background: url("../../assets/image/dt.png") 100% 100% no-repeat;
      background-position: center;
      .steps {
        display: flex;
        column-gap: 20px;
        align-items: center;
        &_list {
          flex: 1;
          margin-top: 60px;
          display: flex;
          overflow: hidden;
          &_item {
            display: flex;
            flex-direction: column;
            row-gap: 50px;
            align-items: center;
            width: 341px;
            flex-shrink: 0;
            transition: all 0.3s ease;
            .year {
              font-size: 20px;
              font-weight: bold;
              color: #ffa71d;
            }
            .line {
              width: 100%;
              height: 2px;
              background: #ffa71d;
              position: relative;
              &::before {
                content: "";
                display: block;
                width: 18px;
                height: 18px;
                background: #fff;
                border: 2px solid #ffa71d;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
              }
            }
            .content {
              width: 80%;
              height: 100px;
              background: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #666;
              font-size: 14px;
              padding: 0 24px;
              box-sizing: border-box;
              position: relative;
              &::before {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 15px solid #fff;
                position: absolute;
                top: -10px;
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }
        }
        .left_btn,
        .right_btn {
          margin-top: -15px;
          width: 46px;
          height: 46px;
          border: 1px solid #eeeeee;
          background: #fff;
          flex-shrink: 0;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #999999;
          -webkit-user-select: none; /* Safari */
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* IE 10+/Edge */
          user-select: none;
        }
      }
    }
  }
  .honor {
    width: $width;
    margin: 0 auto;
    background: #fff;
    padding: 80px 50px;
    box-sizing: border-box;
    &_list {
      margin-top: 60px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 130px;
      &_item {
        height: 170px;
        width: 100%;
        background: url("../../assets/image/ry.png") 100% 100% no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
        padding-top: 10px;
        box-sizing: border-box;
        .year {
          color: #ffa71d;
          font-size: 20px;
          font-weight: bold;
        }
        .line {
          width: 110px;
          height: 1px;
          background: #eeeeee;
        }
        .content {
          color: #666;
          font-size: 16px;
          text-align: center;
          width: 95px;
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 550px;
    background: url("../../assets/image/jrwm.png") 100% 100% no-repeat;
    background-position: center;
    background-size: cover;
    .main {
      width: $width;
      height: 100%;
      padding: 80px 50px;
      box-sizing: border-box;
      margin: 0 auto;
      color: #fff;
      font-size: 24px;
      .title {
        font-size: 40px;
      }
      .sub_title {
        font-size: 90px;
        font-weight: 900;
        opacity: 0.3;
        margin-bottom: 50px;
      }
      span {
        margin-bottom: 20px;
      }
      .email {
        display: block;
        margin-top: 20px;
      }
    }
  }
}
</style>
